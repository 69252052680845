(<template>
  <div class="next-job">
    <div class="next-job_wrapper">
      <div class="next-job__info">
        <template v-if="hasNextJob">
          <p class="next-job__id">{{ nextJobId }}</p>
          <p class="next-job__start-time">{{ startTime }}</p>
        </template>
        <template v-else>
          <p class="next-job__empty">-</p>
        </template>
      </div>
      <div class="next-job__links">
        <button v-if="godmodeDemanderUrl"
                class="sk-link sk-link--default godmode-link"
                @click="openGodmodeLink(godmodeDemanderUrl)">{{ $gettext('[K]') }}</button>
        <button v-if="godmodeSupplierUrl"
                class="sk-link sk-link--default godmode-link"
                @click="openGodmodeLink(godmodeSupplierUrl)">{{ $gettext('[T]') }}</button>
      </div>
    </div>
  </div>
</template>)

<script>
  export default {
    computed: {
      startTime() { return this.$moment(this.params.data?.startTime).format('YYYY.MM.DD HH:mm'); },
      nextJob() { return this.params.data?.nextJob; },
      hasNextJob() { return this.nextJob ? Object.keys(this.nextJob).length > 0 : false; },
      nextJobId() { return this.nextJob?.id; },
      godmodeSupplierUrl() { return this.nextJob?.godmodeSupplierUrl; },
      godmodeDemanderUrl() { return this.nextJob?.godmodeDemanderUrl; }
    },
    methods: {
      openGodmodeLink(link) {
        window.open(link, '_blank');
      }
    }
  };
</script>
<style scoped>
.next-job {
  width: 100%;
  height: 100%;
}

.next-job_wrapper {
  display: flex;
  justify-content: space-between;
  min-height: 50px;
  margin: 2px 0;
  padding: 6px 8px;
  border-radius: 8px;
  background-color: #f4f5f7;
}

.next-job__id {
  color: #ff5b24;
}

.next-job__info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.next-job__start-time {
  color: #555;
  font-size: 12px;
}

.next-job__links {
  margin-top: auto;
  margin-bottom: auto;
}

.godmode-link:not(:first-child) {
  margin-left: 5px;
}

.next-job__empty {
  margin: auto;
}
</style>
