<template>
  <sk-chip :label="supplierTypeText"
           :color="supplierTypeColor" />
</template>

<script>
  import helpers from '@/helpers';

  export default {
    computed: {
      supplierTypeText() { return this.supplierType?.text || ''; },
      supplierTypeColor() { return this.supplierType?.color || 'grey'; },
      supplierType() {
        return helpers.getAdminInfo.supplierType(this, this.params.data?.type);
      }
    },
  };
</script>
