<script>
  import CNextJob from '@/components/grid/cells/customer/CNextJob';

  export default {
    extends: CNextJob,
    computed: {
      nextJob() { return this.params.data?.interpretation?.nextJob; },
      hasNextJob() { return this.nextJob ? Object.keys(this.nextJob).length > 0 : false; },
      nextJobId() { return this.nextJob?.id; },
      godmodeSupplierUrl() { return this.nextJob?.godmodeSupplierUrl; },
      godmodeDemanderUrl() { return this.nextJob?.godmodeDemanderUrl; },
      startTime() { return this.$moment(this.nextJob?.startTime).format('YYYY.MM.DD HH:mm'); },
    },
  };
</script>
