(<template>
  <div class="suppliers">
    <div class="suppliers__header">
      <div class="suppliers__header--left">
        <h1 class="suppliers__header-title">{{ $gettext('Suppliers') }}</h1>
      </div>
      <div>
        <button class="sk-btn sk-btn--white suppliers__header-create-button"
                @click="openCreateSupplierModal">{{ $gettext('Create supplier') }}</button>
      </div>
    </div>
    <div class="suppliers__cont">

      <!------- All Tabs ------->
      <div class="suppliers-tabs__cont">
        <div class="suppliers__wrapper">
          <multiselect-tab v-for="tab in leftSideTabs"
                           :key="tab.tab"
                           :preselected-value="tab.selectedList"
                           :count="tab.count"
                           :with-avatar="tab.withAvatar"
                           :tab="tab" />
        </div>
        <div class="suppliers__wrapper">
          <multiselect-tab v-for="tab in rightSideTabs"
                           :key="tab.tab"
                           :preselected-value="tab.selectedList"
                           :count="tab.count"
                           :with-avatar="tab.withAvatar"
                           :left-side-icon="tab.leftSideIcon"
                           :tab="tab" />
        </div>
      </div>
      <!------- Grid Component ------->
      <ag-grid :column-defs="columnDefs"
               :grid-options="gridOptions"
               :fetch-data-func="fetchDataFunc"
               :grid-data="gridData"
               @interface="getChildInterface" />
    </div>
  </div>
</template>)

<script>
/* eslint-disable vue/no-unused-components */
// For Grid filter components
  import CustomerNameFilter from '@/components/grid/filters/shared/TextFilter';
  import StandbyFilter from '@/components/grid/filters/StandbyFilter';
  import SupplierTypeFilter from '@/components/grid/filters/SupplierTypeFilter';
  import GenderFilter from '@/components/grid/filters/GenderFilter';
  import IdFilter from '@/components/grid/filters/IdFilter';
  import PageWithGridMixin from '@/mixins/PageWithGridMixin';
  // ---- Cells ----//
  import CId from '@/components/grid/cells/shared/CId';
  import CSupplierType from '@/components/grid/cells/supplier/CSupplierType';
  import CName from '@/components/grid/cells/supplier/CName';
  import CSupplierNextJob from '@/components/grid/cells/supplier/CSupplierNextJob';
  import CGender from '@/components/grid/cells/shared/CGender';
  import CStatus from '@/components/grid/cells/supplier/CStatus';
  import CStandBy from '@/components/grid/cells/supplier/CStandBy';
  import CInterpretationStats from '@/components/grid/cells/shared/CInterpretationStats';
  import {mapState} from 'vuex';

  export default {
    components: {
      'customer-filter': CustomerNameFilter,
      'gender-filter': GenderFilter,
      'standby-filter': StandbyFilter,
      'supplier-filter': SupplierTypeFilter,
      'id-filter': IdFilter,
      'c-id': CId,
      'c-supplier-type': CSupplierType,
      'c-name': CName,
      'c-status': CStatus,
      'c-interpretation-stats': CInterpretationStats,
      'c-gender': CGender,
      'c-standby': CStandBy,
      'c-next-job': CSupplierNextJob,
      'ag-grid': () => import('@/components/grid/GridComponent')
    },
    mixins: [PageWithGridMixin],
    data() {
      return {
        currentView: this.$route.query.view || 'active',
        currentPage: Number(this.$route.query.page) || 1,
        items: this.$store.state.filterItemsAmount || 10,
        ransackParams: {},
        fetchGridDataAction: 'AllSuppliersStore/getSuppliers',
        gridOptions: {
          pagination: true,
          suppressPaginationPanel: true,
          suppressMovableColumns: true,
          suppressColumnVirtualisation: false,
          paginationPageSize: this.$store.state.filterItemsAmount,
          paginationAutoPageSize: false,
          suppressExcelExport: true,
          suppressRowClickSelection: true,
          rowHeight: 65,
          popupParent: null,
          rowStyle: {'max-height': '58px'},
          rowModelType: 'infinite',
          // ------ cacheBlockSize Equal to pageSize no caching ------- //
          cacheBlockSize: this.$store.state.filterItemsAmount,
          maxBlocksInCache: 1,
          maxConcurrentDatasourceRequests: 2,
          domLayout: 'autoHeight',
          floatingFiltersHeight: 60
        },
        // ------- Tabs Data ------- //
        tabForUpdate: ''
      };
    },
    computed: {
      ...mapState('AllSuppliersStore', {
        suppliersData: (state) => state.suppliersData || {},
        updateSupplierPageGrid: (state) => state.updateSupplierPageGrid || {},
      }),
      statistics() { return this.suppliersData.statistics; },
      activeCount() { return this.statistics?.active; },
      pausedCount() { return this.statistics?.paused; },
      blockedCount() { return this.statistics?.blocked; },
      retiredCount() { return this.statistics?.retired; },
      bannedCount() { return this.statistics?.banned; },
      deletedCount() { return this.statistics?.deleted; },
      deceasedCount() { return this.statistics?.deceased; },
      allCount() { return this.statistics?.all; },
      anniversariesCount() { return this.statistics?.anniversary; },
      columnDefs() {
        return [
          {
            headerName: this.$gettext('ID'),
            field: 'id',
            maxWidth: 150,
            flex: 1,
            sortable: true,
            floatingFilterComponent: 'id-filter',
            ...this.textFilterNoMenu,
            cellRendererSelector: (params) => {
              return {
                component: 'c-id',
                params: params
              };
            }
          },
          {headerName: this.$gettext('Type'),
           field: 'supplierType',
           flex: 1,
           floatingFilterComponent: 'supplier-filter',
           ...this.multiSelectFilterParams,
           cellRendererSelector: (params) => {
             return {
               component: 'c-supplier-type',
               params: params
             };
           },
           condition: true},
          {headerName: this.$gettext('Name, email, phone'),
           field: 'lookupFields',
           flex: 2,
           sortable: true,
           ...this.textFilterNoMenu,
           floatingFilterComponent: 'customer-filter',
           cellRendererSelector: (params) => {
             return {
               component: 'c-name',
               params: params
             };
           }},
          {headerName: this.$gettext('Status'),
           field: 'status',
           flex: 1,
           maxWidth: 140,
           cellRendererSelector: (params) => {
             return {
               component: 'c-status',
               params: params
             };
           }},
          {headerName: this.$gettext('Gender'),
           field: 'sex',
           flex: 1,
           sortable: true,
           ...this.multiSelectFilterParams,
           floatingFilterComponent: 'gender-filter',
           cellRendererSelector: (params) => {
             return {
               component: 'c-gender',
               params: params
             };
           }},
          {headerName: this.$gettext('Standby'),
           field: 'interpreterCanStandby',
           minWidth: 150,
           flex: 1,
           sortable: true,
           ...this.multiSelectFilterParams,
           floatingFilterComponent: 'standby-filter',
           cellRendererSelector: (params) => {
             return {
               component: 'c-standby',
               params: params
             };
           }},
          {headerName: this.$gettext('Stats'),
           field: 'interpretationStats',
           flex: 1,
           cellRendererSelector: (params) => {
             return {
               component: 'c-interpretation-stats',
               params: params
             };
           }},
          {headerName: this.$gettext('Next job'),
           minWidth: 250,
           flex: 3,
           field: 'nextJob',
           cellRendererSelector: (params) => {
             return {
               component: 'c-next-job',
               params: params
             };
           }},
        ];
      },
      gridData() {
        return {
          page: this.currentPage,
          items: this.items,
          ransackParams: this.tabRansackParams
        };
      },
      tabRansackParams() {
        switch (this.currentView) {
          case 'active':
            return {
              's[current_account_status_name_eq]': 0,
            };
          case 'paused':
            return {
              's[current_account_status_name_eq]': 1
            };
          case 'blocked':
            return {
              's[current_account_status_name_eq]': 2
            };
          case 'retired':
            return {
              's[current_account_status_name_eq]': 3
            };
          case 'banned':
            return {
              's[current_account_status_name_eq]': 4
            };
          case 'deleted':
            return {
              's[current_account_status_name_eq]': 5
            };
          case 'deceased':
            return {
              's[current_account_status_name_eq]': 6
            };
          case 'anniversaries':
            return {
              's[with_anniversary_today]': true
            };
          case 'all':
            return {};
        }
      },
      isActiveTab() { return this.currentView === 'active'; },
      isPausedTab() { return this.currentView === 'paused'; },
      isBlockedTab() { return this.currentView === 'blocked'; },
      isRetiredTab() { return this.currentView === 'retired'; },
      isBannedTab() { return this.currentView === 'banned'; },
      isDeletedTab() { return this.currentView === 'deleted'; },
      isDeceasedTab() { return this.currentView === 'deceased'; },
      isAllView() { return this.currentView === 'all'; },
      isAnniversariesView() { return this.currentView === 'anniversaries'; },
      leftSideTabs() {
        return [
          {
            tab: 'active',
            isActive: this.isActiveTab,
            title: this.$gettext('Active'),
            count: this.activeCount,
            params: this.tabRansackParams,
            onClick: this.setCurrentView
          }, {
            tab: 'paused',
            isActive: this.isPausedTab,
            title: this.$gettext('Paused'),
            count: this.pausedCount,
            params: this.tabRansackParams,
            onClick: this.setCurrentView
          }, {
            tab: 'blocked',
            isActive: this.isBlockedTab,
            title: this.$gettext('Blocked'),
            count: this.blockedCount,
            params: this.tabRansackParams,
            onClick: this.setCurrentView
          }, {
            tab: 'retired',
            isActive: this.isRetiredTab,
            title: this.$gettext('Retired'),
            count: this.retiredCount,
            params: this.tabRansackParams,
            onClick: this.setCurrentView
          }, {
            tab: 'banned',
            isActive: this.isBannedTab,
            title: this.$gettext('Banned'),
            count: this.bannedCount,
            params: this.tabRansackParams,
            onClick: this.setCurrentView
          }, {
            tab: 'deleted',
            isActive: this.isDeletedTab,
            title: this.$gettext('Deleted'),
            count: this.deletedCount,
            params: this.tabRansackParams,
            onClick: this.setCurrentView
          }, {
            tab: 'deceased',
            isActive: this.isDeceasedTab,
            title: this.$gettext('Deceased'),
            count: this.deceasedCount,
            params: this.tabRansackParams,
            onClick: this.setCurrentView
          },
          {
            tab: 'all',
            isActive: this.isAllView,
            title: this.$gettext('All'),
            count: this.allCount,
            onClick: this.setCurrentView
          }
        ];
      },
      rightSideTabs() {
        return [
          {
            tab: 'anniversaries',
            isActive: this.isAnniversariesView,
            title: this.$gettext('Anniversaries'),
            count: this.anniversariesCount,
            params: this.tabRansackParams,
            onClick: this.setCurrentView,
            leftSideIcon: 'anniversary'
          }
        ];
      }
    },
    watch: {
      updateSupplierPageGrid() {
        this.updateGridData();
      }
    },
    methods: {
      openCreateSupplierModal() {
        this.$store.commit('ModalStore/setModal', {
          component: 'create-edit-supplier-modal',
          classes: ['sk-modal--new', 'sk-modal--customer-actions'],
          width: 540,
          data: {
            title: this.$gettext('Create supplier'),
            context: this,
            successHandlingCallbackName: 'refreshSupplierGrid'
          }
        });
      },
      refreshSupplierGrid() {
        this.$store.commit('AllSuppliersStore/refreshSupplierGrid');
      }
    },
    beforeRouteEnter(to, from, next) {
      if (to.query.view == {} || to.query.view == null || to.query.view == undefined) {
        next((vm) => {
          return {path: to.path, query: {view: 'active', page: 1, items: vm.$store.state.filterItemsAmount}};
        });
      }
      next();
    },
    beforeRouteLeave(to, from, next) {
      this.$store.commit('AllSuppliersStore/clearStore');
      next();
    },
    childInterface: {
      updateData: () => {}
    },
  };
</script>
<style scoped>
.suppliers {
  margin: 10px 20px;
}

.suppliers__cont {
  padding: 10px 20px 20px 20px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 0 9px 0 rgb(0 0 0 / 5%);
}

.suppliers__wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.suppliers__header-create-button {
  padding-right: 15px;
  padding-left: 40px;
  border: 1px solid #d3d5db;
  background-color: #fff;
  background-image: var(--plus-icon);
  background-position: 5.55% 50%;
  background-size: 22px auto;
  background-repeat: no-repeat;
}

.suppliers-tabs__cont {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 20px;
  border-bottom: 1px solid #d3d5de;
}

.suppliers__header {
  display: flex;
  justify-content: space-between;
  padding: 15px 0;
}

.suppliers__header--left {
  display: flex;
}

.suppliers__header-title {
  font-size: 24px;
}
</style>
