<template>
  <section class="supplier">
    <sk-select :items-list="customerTypes"
               class="supplier__select"
               :preselected-value="selectedSupplierType"
               :default-value="placeholder"
               @csvaluechanged="onInputBoxChanged" />
    <button v-if="selectedSupplierType"
            class="supplier__reset-icon"
            @click="resetType"></button>
  </section>
</template>

<script>
  export default {
    data() {
      return {
        selectedSupplierType: ''
      };
    },
    computed: {
      customerTypes() {
        return [
          {id: 'interpreterSupplierType', name: this.$gettext('Interpreter')},
          // {id: 'translatorSupplierType', name: this.$gettext('Translator')},
          {id: 'bothSupplierType', name: this.$gettext('Both')}
        ];
      },
      placeholder() { return this.$gettext('Select a customer'); },

    },
    methods: {
      onInputBoxChanged(value) {
        this.selectedSupplierType = value;
        if (this.selectedSupplierType === '') {
          return;
        }
        this.params.parentFilterInstance((instance) => {
          instance.onFloatingFilterChanged('inList', value);
        });
      },

      onParentModelChanged(parentModel) {
        // When the filter is empty we will receive a null value here
        if (!parentModel) {
          this.selectedSupplierType = '';
        }
      },
      resetType() {
        this.selectedSupplierType = '';
        this.params.parentFilterInstance((instance) => {
          instance.onFloatingFilterChanged('', '');
        });
      }
    }
  };
</script>

<style>
.supplier .sk-select {
  background-color: #fff;
  font-weight: normal;
  font-size: 13px;
}

.supplier {
  display: flex;
  width: 100%;
}

.supplier__select {
  width: 80%;
}

.supplier__reset-icon {
  align-self: center;
  width: 20px;
  height: 25px;
  margin-left: 5px;
  background-image: url(~@assets/imgs/undefined_imgs/close_x_icon_blue.svg);
  background-position: 50% 50%;
  background-size: 13px auto;
  background-repeat: no-repeat;
  cursor: pointer;
}
</style>
