<script>
  import CPerson from '@/components/grid/cells/skill/interpretation/CInterpreter';

  export default {
    extends: CPerson,
    computed: {
      showAddBtn() { return false; },
      showEditBtn() { return true; }
    },
    methods: {
      onEditClick() {
        this.$store.commit('ModalStore/setModal', {
          component: 'create-edit-supplier-modal',
          classes: ['sk-modal--new', 'sk-modal--customer-actions'],
          width: 540,
          data: {
            title: this.$gettext('Edit supplier'),
            context: this,
            successHandlingCallbackName: 'refreshSupplierGrid',
            supplier: {
              id: this.propData.id,
              firstName: this.firstName,
              lastName: this.lastName,
              email: this.email,
              telCode: this.propData.phoneCode,
              telNumber: this.propData.phoneNumber
            }
          }
        });
      },
      refreshSupplierGrid() {
        this.$store.commit('AllSuppliersStore/refreshSupplierGrid');
      }
    }
  };
</script>
