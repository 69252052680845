<script>
  import CStatus from '@/components/grid/cells/skill/interpretation/CStatus';

  export default {
    extends: CStatus,
    computed: {
      sessionTypesStatuses() { return this.propData?.interpretation?.sessionTypesStatuses; },
    }
  };
</script>
